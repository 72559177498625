import BackgroundImage from '../../components/BackgroundImage'
import CurriculumBody from '../../components/CurriculumBody'
import Header from '../../components/Header'

const Curriculo = () => {
  return (
    <div>
      <div>
        <BackgroundImage />
        <Header />
        <CurriculumBody />
      </div>
    </div>
  )
}
export default Curriculo

import styled from 'styled-components'
import { breakpoints, colors } from '../../styles'

type Props = {
  $background?: string
}

export const CurriculumStyle = styled.section`
  background-color: ${colors.backgroundBlue};
  border-radius: 32px;
  padding: 32px;
  margin: 32px auto 0;
  h2 {
    text-align: center;
  }
`

export const CurriculumList = styled.ul<Props>`
  margin: 8px 8px 16px;

  li {
    position: relative;
    line-height: 30px;

    padding: 16px;
    margin: 8px;

    &::before {
      top: 0;
      left: 0;
      z-index: -1;
      border-radius: 16px;
      position: absolute;
      content: '';
      width: 100%;
      height: 100%;
      background-color: ${(props) =>
        props.$background ? colors.green : colors.backgroundHeader};
    }

    @media (max-width: ${breakpoints.tablet}) {
      text-align: center;
      .content {
        display: none;
      }
    }
  }
`

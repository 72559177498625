import About from '../../components/About'
import BackgroundImage from '../../components/BackgroundImage'
import Header from '../../components/Header'
import ProjectsList from '../../components/ProjectsList'

const Home = () => {
  return (
    <div>
      <BackgroundImage />
      <Header />
      <About />
      <ProjectsList title="Projetos" featured={true} />
    </div>
  )
}

export default Home

import { BrowserRouter } from 'react-router-dom'
import Rotas from './routes'
import GlobalStyle from './styles'

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <GlobalStyle />
        <Rotas />
      </BrowserRouter>
    </div>
  )
}

export default App

import { AboutStyle } from './styles'

const About = () => {
  return (
    <div className="container">
      <AboutStyle>
        <h2>Bem-vindo!</h2>
        <p>
          Meu nome é Fábio, sou desenvolvedor web. Possuo experiência em React,
          VueJs, Bootstrap, pré-processadores CSS LESS e SASS, Grunt, Gulp e
          GIT. Além disso, possuo conhecimentos em Python e PHP, designs
          responsivos, testes automatizados com Jest, Cypress, Pytest e Selenium
          e análise de dados com Python e R.
        </p>
      </AboutStyle>
    </div>
  )
}

export default About

import BackgroundImage from '../../components/BackgroundImage'
import Header from '../../components/Header'
import ProjectsList from '../../components/ProjectsList'

const Portfolio = () => {
  return (
    <div>
      <BackgroundImage />
      <Header />
      <ProjectsList title="Portfolio" />
    </div>
  )
}

export default Portfolio

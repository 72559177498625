import styled from 'styled-components'
import { breakpoints, colors } from '../../styles'

export const AboutStyle = styled.div`
  padding: 24px;
  background-color: ${colors.backgroundBlue};
  border-radius: 32px;
  margin: 16px auto;

  h2 {
    margin-bottom: 8px;
  }

  p {
    font-size: 18px;
  }

  @media (max-width: ${breakpoints.desktop}) {
    width: 90%;
    text-align: center;
  }
`

import styled from 'styled-components'
import galaxy from './../../assets/images/galaxy.jpg'

export const BackgroundDiv = styled.div`
  background-image: url(${galaxy});
  background-size: cover;
  height: 200%;
  width: 150%;
  overflow: hidden;
  position: fixed;
  top: 0;
  left: 0;
  z-index: -10;
`

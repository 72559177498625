import { createGlobalStyle } from 'styled-components'

export const colors = {
  backgroundHeader: '#030526',
  backgroundBlue: 'rgba(7,21,64,0.7)',
  lightBlue: '#68Dbe2',
  defaultTextColor: '#ddd',
  green: 'rgba(0,30,20, 1)'
}

export const breakpoints = {
  tablet: '767px',
  desktop: '1024px'
}

const GlobalStyle = createGlobalStyle`
  *{
    margin: 0;
    padding: 0;
    list-style: none;
    box-sizing: border-box;
    font-family: 'Roboto', sans-serif;
  }

  h1,h2, h3{
    font-family: 'Montserrat Alternates', sans-serif;
  }

  a{
    text-decoration: none;
    color: ${colors.defaultTextColor};
  }

  .container {
    max-width: 1024px;
    width: 100%;
    @media(max-width: ${breakpoints.desktop}){
      width:94%;
    }
    margin: auto;
  }

body{
    color: ${colors.defaultTextColor};

  }

`

export default GlobalStyle

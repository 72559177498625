import styled from 'styled-components'
import { breakpoints, colors } from '../../styles'

export const ProjectCardStyle = styled.a`
  display: grid;
  grid-template-rows: 1fr 2fr;

  cursor: pointer;

  background-color: ${colors.backgroundHeader};
  padding: 16px;
  border-radius: 16px;
  text-align: center;
  position: relative;

  height: 400px;

  width: 95%;
  max-width: 320px;

  border: 2px solid transparent;
  transition: ease-in-out 0.4s;

  &:hover {
    border: 2px solid darkturquoise;
  }

  @media (max-width: ${breakpoints.tablet}) {
    width: 100%;
    padding: 8px;
  }
`

export const ProjectCardText = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  max-height: 100%;
  gap: 10px;
  margin-top: 8px;

  p {
    text-align: left;
  }

  h3 {
    text-align: left;
  }

  .test {
    display: flex;
    justify-content: space-between;
  }

  #techs_list {
    display: flex;
    flex-flow: wrap;
    justify-content: center;

    gap: 6px;

    width: 90%;

    li {
      font-size: small;
      background-color: ${colors.backgroundBlue};
      color: ${colors.defaultTextColor};
      padding: 4px;
      border: 1px solid ${colors.lightBlue};
      border-radius: 4px;
    }
  }
`

export const LinksDiv = styled.div`
  display: block;
  text-align: left;
  svg {
    transition: all ease-in-out 0.4s;

    &:hover {
      color: ${colors.lightBlue};
      transform: scale(1.1);
    }
  }
`
export const ImageTag = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;

  img {
    margin: auto;
    max-width: 90%;
    max-height: 160px;
    border-radius: 16px;
    border: 2px solid darkturquoise;
  }
`

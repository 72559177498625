import styled from 'styled-components'
import { breakpoints, colors } from '../../styles'

export const ProjectsListStyle = styled.div`
  background-color: ${colors.backgroundBlue};
  border-radius: 32px;
  padding: 20px;
  margin: 0 auto;

  h2 {
    text-align: center;
    margin-bottom: 16px;
  }

  @media (max-width: ${breakpoints.desktop}) {
    max-width: 100%;
  }
  @media (max-width: ${breakpoints.tablet}) {
    max-width: 100%;
    padding: 10px;
  }
`

export const List = styled.ul`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 8px;
`
export const SelectField = styled.select`
  background-color: ${colors.backgroundBlue};
  padding: 8px;
  border-radius: 8px;
  color: white;
  text-align: center;
  display: block;
  margin: 8px auto 16px;
`

import ProjectCard from '../ProjectCard'
import { List, ProjectsListStyle, SelectField } from './styles'
import { Projects, ProjectsData } from '../../data'
import { useEffect, useState } from 'react'

type Props = {
  title: string
  featured?: boolean
}

const ProjectsList = ({ title, featured }: Props) => {
  const [filter, setFilter] = useState('Todos')
  const [filteredProjects, setFilteredProjects] = useState<ProjectsData[]>([])

  useEffect(() => {
    if (filter === 'Todos') {
      setFilteredProjects(Projects)
    } else {
      const projects = Projects.filter((item) => item.techs.includes(filter))
      setFilteredProjects(projects)
    }
  }, [filter])

  const handleSelect = (event: any) => {
    setFilter(event.target.value)
  }

  const techs: string[] = ['Todos']
  Projects.map((item) => item.techs.map((tech) => techs.push(tech)))

  const uniqueTechs: string[] = []
  techs.map((item) => {
    if (!uniqueTechs.includes(item)) {
      uniqueTechs.push(item)
    }
  })

  return (
    <div className="container">
      <ProjectsListStyle>
        <h2>{title}</h2>

        {!featured && (
          <SelectField onChange={handleSelect}>
            {uniqueTechs.map((item) => (
              <option value={item} key={item}>
                {item}
              </option>
            ))}
          </SelectField>
        )}

        <List>
          {featured
            ? Projects.filter((item) => item.featured === true).map((item) => (
                <li key={item.title}>
                  <ProjectCard
                    title={item.title}
                    deploy={item.deploy}
                    description={item.description}
                    github={item.github}
                    image={item.image ? item.image : ''}
                  />
                </li>
              ))
            : filteredProjects.map((item) => (
                <li key={item.title}>
                  <ProjectCard
                    title={item.title}
                    deploy={item.deploy}
                    description={item.description}
                    github={item.github}
                    image={item.image ? item.image : ''}
                    techs={item.techs}
                  />
                </li>
              ))}
        </List>
      </ProjectsListStyle>
    </div>
  )
}
export default ProjectsList

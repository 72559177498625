import { Courses } from '../../data'
import { CurriculumList, CurriculumStyle } from './styles'

const CurriculumBody = () => {
  return (
    <CurriculumStyle className="container">
      <CurriculumList id="circle">
        <h2>Formação</h2>
        {Courses.filter((item) => item.category === 'Formacao').map((item) => (
          <li key={item.title}>
            <b>CURSO: </b>
            {item.title}
            <br />
            <b>CARGA HORÁRIA: </b>
            {item.workload}h<br />
            <b>ESCOLA: </b>
            {item.school}
            <br />
            <b>CONCLUÍDO EM: </b>
            {item.conclusionYear === 9999
              ? 'Em andamento'
              : item.conclusionYear}
          </li>
        ))}
      </CurriculumList>
      <CurriculumList id="circle" $background="purple">
        <h2>Cursos livres</h2>
        {Courses.filter((item) => item.category !== 'Formacao').map((item) => (
          <li key={item.title}>
            <b>CURSO: </b>
            {item.title}
            <br />
            <b>CARGA HORÁRIA: </b>
            {item.workload}h<br />
            <div className="content">
              <b>CONTEÚDO: </b>
              {item.description}
              <br />
            </div>
            <b>ESCOLA: </b>
            {item.school}
            <br />
            <b>CONCLUÍDO EM: </b>
            {item.conclusionYear === 9999
              ? 'Em andamento'
              : item.conclusionYear}
          </li>
        ))}
      </CurriculumList>
    </CurriculumStyle>
  )
}
export default CurriculumBody

import styled from 'styled-components'
import { breakpoints, colors } from '../../styles'

type Props = {
  $directionColumn?: boolean
}

type HeaderListItemProps = {
  $isText?: boolean
}

export const HeaderStyle = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 32px;
  margin: 8px 100px 20px;
  border-radius: 40px;

  background-color: ${colors.backgroundHeader};
  text-shadow: 1px 1px black;

  h3 {
    margin-left: 20px;
  }

  @media (max-width: ${breakpoints.desktop}) {
    width: 94%;
    margin: 16px auto;
  }

  @media (max-width: ${breakpoints.tablet}) {
    flex-direction: column;
    text-align: center;

    h1 {
      font-size: 24px;
    }

    h3 {
      margin-left: 0;
      margin-bottom: 16px;
    }
  }
`
export const HeaderList = styled.ul<Props>`
  display: flex;
  gap: 36px;
  justify-content: space-around;
  align-items: center;
  padding: 8px;

  @media (max-width: ${breakpoints.tablet}) {
    flex-direction: ${(props) => (props.$directionColumn ? 'column' : 'row')};
    gap: ${(props) => (props.$directionColumn ? '8px' : '36px')};
    margin-bottom: ${(props) => (props.$directionColumn ? '16px' : '8px')};
  }
`
export const HeaderListItem = styled.li<HeaderListItemProps>`
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 20px;
  transition: 0.4s;

  &:hover {
    transition: 0.4s;
  }

  a {
    transition: 0.4s;
  }

  a:hover {
    color: ${colors.lightBlue};
    transition: 0.8s;
  }

  &.linkText {
    width: fit-content;
  }

  @media (max-width: ${breakpoints.tablet}) {
    width: 24px;
    height: 24px;
  }
`

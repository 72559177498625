import recipes_cover from './../assets/images/snaps/recipes_cover.png'
import localweather_cover from './../assets/images/snaps/localweather_cover.png'
import sorteador_cover from './../assets/images/snaps/sorteador_cover.png'
import efood_cover from './../assets/images/snaps/efood_cover.png'
import wildlife_cover from './../assets/images/snaps/wildlife_cover.png'
import disney_cover from './../assets/images/snaps/disney_cover.png'
import weatherapp_cover from './../assets/images/snaps/weatherapp_cover.png'
import eplay_cover from './../assets/images/snaps/eplay_cover.png'
import calculadora_cover from './../assets/images/snaps/calculadora_cover.png'
import contactlist_cover from './../assets/images/snaps/contactlist_cover.png'
import pinguimconsultoria_cover from './../assets/images/snaps/pinguimconsultoria_cover.png'
import imc_cover from './../assets/images/snaps/imc_cover.png'

export type ProjectsData = {
  title: string
  description: string
  github: string
  deploy: string
  featured: boolean
  techs: string[]
  image: string
}

export type CoursesData = {
  title: string
  conclusionYear: number
  school: string
  description: string
  workload: number
  type: string
  category: string
}

export const Projects: ProjectsData[] = [
  {
    title: 'Recipes',
    deploy: 'https://fbpalacio.pythonanywhere.com/',
    description: 'Rede social de receitas utilizando django.',
    github: 'https://github.com/fabiopalacio/curso-django-projeto1',
    featured: true,
    techs: ['Python', 'Django', 'Javascript', 'Pytest', 'Selenium', 'API'],
    image: recipes_cover
  },
  {
    title: 'Previsão do tempo',
    deploy: 'https://vueweather.fabiopalacio.com/',
    description: 'Aplicação que apresenta o clima das cidades inseridas.',
    github: 'https://github.com/fabiopalacio/weatherAppVueJs',
    featured: false,
    techs: ['VueJS', 'Javascript', 'Vite', 'Tailwind', 'API'],
    image: localweather_cover
  },
  {
    title: 'Sorteador de números',
    deploy: 'https://sorteadorgrunt.fabiopalacio.com/',
    description:
      'Sorteador de números usando Javascript, HTML, CSS e Grunt para automatizar tarefas.',
    github: 'https://github.com/fabiopalacio/sorteador_grunt',
    featured: false,
    techs: ['Javascript', 'Grunt', 'Less'],
    image: sorteador_cover
  },
  {
    title: 'E-Food',
    deploy: 'https://efood.fabiopalacio.com',
    description:
      'Aplicação web para restaurantes desenvolvida em React. Lista restaurantes e cardápios e realiza pedido via API.',
    github: 'https://github.com/fabiopalacio/efood',
    featured: true,
    techs: ['TypeScript', 'React', 'API'],
    image: efood_cover
  },
  {
    title: 'WildLife Disney+',
    deploy: 'https://disneywild.fabiopalacio.com/',
    description:
      'Cópia da página de conteúdo da Disney+ utilizando Gulp para automatização de tarefas.',
    github: 'https://github.com/fabiopalacio/disneyWild',
    featured: true,
    techs: ['Javascript', 'jQuery', 'Gulp', 'Scss'],
    image: wildlife_cover
  },

  {
    title: 'Disney+',
    deploy: 'https://disneyplus.fabiopalacio.com/',
    description: 'Cópia da página de divulgação do Disney+',
    github: 'https://github.com/fabiopalacio/clone_disneyplus',
    featured: false,
    techs: ['Javascript', 'Gulp', 'Scss'],
    image: disney_cover
  },
  {
    title: 'Calculadora do IMC',
    deploy: 'https://imc.fabiopalacio.com/',
    description:
      'Aplicação React para cálculo de I.M.C. criada utilizando Vite.',
    github: 'https://github.com/fabiopalacio/imc-calculator',
    featured: false,
    techs: ['Javascript', 'React', 'Vite'],
    image: imc_cover
  },
  {
    title: 'Calculadora Aritmética',
    deploy: 'https://calculadora.fabiopalacio.com/',
    description: 'Aplicação VueJs para cálculos aritméticos.',
    github: 'https://github.com/fabiopalacio/calculadora_aritmetica',
    featured: false,
    techs: ['Javascript', 'VueJS', 'Vite'],
    image: calculadora_cover
  },

  {
    title: 'E-Play Commerce',
    deploy: 'https://eplay.fabiopalacio.com/',
    description: 'Aplicação React para e-commerce de jogos',
    github: 'https://github.com/fabiopalacio/E-Play',
    featured: false,
    techs: ['Javascript', 'React', 'TypeScript', 'API'],
    image: eplay_cover
  },

  {
    title: 'App Previsão do tempo',
    deploy: 'https://weatherapp.fabiopalacio.com/',
    description:
      'Aplicação que disponibiliza informações sobre a condição metereológica atual.',
    github: 'https://github.com/fabiopalacio/weather-app',
    featured: false,
    techs: ['Javascript', 'React', 'API'],
    image: weatherapp_cover
  },
  {
    title: 'Lista de contatos',
    deploy: 'https://reactcontactlist.fabiopalacio.com/',
    description:
      'Aplicação react, utilizando redux, input-react-mask, react-router-dom e styled-components',
    github: 'https://github.com/fabiopalacio/ListaContatos',
    featured: false,
    techs: ['Javascript', 'TypeScript', 'React'],
    image: contactlist_cover
  },
  {
    title: 'Pinguim Consultoria',
    deploy: 'https://pinguim.fabiopalacio.com/',
    description:
      'Página web de uma empresa fictícia usando JavaScript, jQuery e Bootstrap',
    github: 'https://github.com/fabiopalacio/pinguimConsultoria',
    featured: false,
    techs: ['Javascript', 'jQuery', 'Bootstrap'],
    image: pinguimconsultoria_cover
  }
]

export const Courses: CoursesData[] = [
  {
    title: 'Profissão Desenvolvedor Python Full Stack',
    conclusionYear: 9999,
    description:
      'Curso Full Stack utilizando Python para Back-End e HTML, CSS e JavaScript para Front-end, frameworks Bootstrap, React e VueJS, testes com Cypress e JestJS, os pré-processadores LESS e SASS, automatações com Gulp e Grunt. ',
    school: 'EBAC - Escola Britânica de Artes Criativas & Tecnologia',
    workload: 75,
    type: 'Curso Livre',
    category: 'Formacao'
  },
  {
    title: 'Mestrado em Biodiversidade e Conservação da Natureza',
    conclusionYear: 2021,
    description:
      'Dissertação sobre dispersão de sementes por aves do subbosque da região neotropical. Análises estatísticas realizadas em R e Python.',
    school: 'Universidade Federal de Juiz de Fora',
    workload: 200,
    type: 'Pós-Graduação stricto-sensu',
    category: 'Formacao'
  },
  {
    title: 'Bacharelado em Ciências Biológicas',
    conclusionYear: 2016,
    description:
      'Curso de nível superior com foco na área académica. Desenvolvi habilidades com planejamento e elaboração de projetos, coleta e análise de dados, e elaboração de relatórios técnicos e científicos.',
    school: 'Universidade Federal de Juiz de Fora',
    workload: 3198,
    type: 'Bacharelado',
    category: 'Formacao'
  },
  {
    title: 'Técnico em Eletrotécnica',
    conclusionYear: 2013,
    description:
      'Curso de nível técnico. Adquiri conhecimentos com eletricidade, manutenção de equipamentos, além de ter o primeiro contato com progração através de Circuitos Integrados Programáveis e Controladores Lógicos Programáveis',
    school: 'IFET - Campus Juiz de Fora',
    workload: 1250,
    type: 'Curso Técnico',
    category: 'Formacao'
  },

  {
    title: 'Machine Learning e Data Science com R de A a Z',
    conclusionYear: 2022,
    description:
      'Aprendizagem Bayesiana, Aprendizagem por Árvores de Decisão, Aprendizagem por Regras, Aprendizagem Baseada em Instâncias, Regressão logística, Máquina de Vetores de Suporte, Redes Neurais Artificiais, Regressões e Agrupamentos.',
    school: 'IA Expert Academy - Jones Granatyr',
    workload: 28,
    type: 'Curso Livre',
    category: 'Others'
  },
  {
    title: 'Formação em Redes',
    conclusionYear: 2021,
    description:
      'Conjunto de cursos livres com temática de redes de computadores.',
    school: 'Alura',
    workload: 49,
    type: 'Curso Livre',
    category: 'Others'
  },
  {
    title: 'Formação: Programação',
    conclusionYear: 2021,
    description:
      'Conjunto de cursos livres utilizando PHP para desenvolvimento WEB.',
    school: 'Alura',
    workload: 60,
    type: 'Curso Livre',
    category: 'Others'
  },
  {
    title: 'Formação: Iniciante em Programação',
    conclusionYear: 2021,
    description:
      'Curso livre com foco em introdução a programação web, utilizando HTML, CSS e JavaScript.',
    school: 'Alura',
    workload: 89,
    type: 'Curso Livre',
    category: 'Others'
  }
]

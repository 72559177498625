import { Routes, Route } from 'react-router-dom'

import Home from './pages/Home'
import Portfolio from './pages/Portfolio'
import Curriculo from './pages/Curriculo'

const Rotas = () => (
  <Routes>
    <Route path="/" element={<Home />} />
    <Route path="/portfolio" element={<Portfolio />} />
    <Route path="/curriculum" element={<Curriculo />} />
  </Routes>
)

export default Rotas
